<template>
  <div class="annual-swiper-component">
    <div ref="demo" id="demo">
      <table align="left" cellspace="0" border="0" cellpadding="0">
        <tr @click="openImg">
          <td ref="demo1" id="demo1" valign="top">
            <table border="0" cellpadding="0" cellspacing="0">
              <tr>
                <td>
                  <table cellSpacing="0" border="0" cellPadding="7">
                    <tbody>
                    <tr align="middle">
                      <td style="padding: 0 3.5px;" v-for="(item, ind) in expertList"
                          :key="ind+999999">
                        <div>
                          <div style="display: flex; margin-bottom: 8px;">
                            <div style="cursor: pointer;">
                              <img :src="item[0]? item[0].picture + '?w=234&h=171' : ''" alt=""
                                   width="234" height="171"
                                   style="display: block; margin-right: 7px">
                              <p style="display: none;">{{ item[0].id }}</p>
                            </div>
                            <div style="cursor: pointer;">
                              <img :src="item[1]? item[1].picture + '?w=234&h=171' : ''" alt=""
                                   width="234" height="171"
                                   style="display: block; margin-right: 7px">
                              <p style="display: none;">{{ item[1] ? item[1].id : '' }}
                              </p>
                            </div>
                          </div>
                          <div style="display: flex; margin-bottom: 8px;">
                            <div style="cursor: pointer; height: 171px;">
                              <img :src="item[2]? item[2].picture + '?w=154&h=171': ''" alt=""
                                   width="154" height="171"
                                   style="display: block; margin-right: 6px">
                              <p style="display: none;">{{ item[2] ? item[2].id : '' }}
                              </p>
                            </div>
                            <div style="cursor: pointer; height: 171px;">
                              <img :src="item[3]? item[3].picture + '?w=154&h=171' : ''" alt=""
                                   width="154" height="171"
                                   style="display: block; margin-right: 6px">
                              <p style="display: none;">{{ item[3] ? item[3].id : '' }}
                              </p>
                            </div>
                            <div style="cursor: pointer; height: 171px;">
                              <img :src="item[4]? item[4].picture + '?w=154&h=171' : ''" alt=""
                                   width="154" height="171">
                              <p style="display: none;">{{ item[4] ? item[4].id : '' }}
                              </p>
                            </div>

                          </div>
                          <div style="display: flex;">
                            <div style="cursor: pointer;">
                              <img :src="item[5]? item[5].picture + '?w=234&h=171' : ''" alt=""
                                   width="234" height="171"
                                   style="display: block; margin-right: 7px">
                              <p style="display: none;">{{ item[5] ? item[5].id : '' }}
                              </p>
                            </div>
                            <div style="cursor: pointer;">
                              <img :src="item[6]? item[6].picture + '?w=234&h=171' : ''" alt=""
                                   width="234" height="171" style="display: block; ">
                              <p style="display: none;">{{ item[6] ? item[6].id : '' }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>

                      <!-- <td style="padding: 0 3.5px;">

                          <div>
                              <div style="display: flex; margin-bottom: 8px;">
                                  <img src="../assets/img/annual-metting/005.jpg" alt=""
                                      width="234" height="171"
                                      style="display: block; margin-right: 7px">
                                  <img src="../assets/img/annual-metting/004.jpg" alt=""
                                      width="234" height="171">
                              </div>
                              <div style="display: flex; margin-bottom: 8px;">
                                  <img src="../assets/img/annual-metting/001.jpg" alt=""
                                      width="154" height="171"
                                      style="display: block; margin-right: 6px">
                                  <img src="../assets/img/annual-metting/005.jpg" alt=""
                                      width="154" height="171"
                                      style="display: block; margin-right: 6px">
                                  <img src="../assets/img/annual-metting/003.jpg" alt=""
                                      width="154" height="171">
                              </div>
                              <div style="display: flex;">
                                  <img src="../assets/img/annual-metting/005.jpg" alt=""
                                      width="234" height="171"
                                      style="display: block; margin-right: 7px">
                                  <img src="../assets/img/annual-metting/004.jpg" alt=""
                                      width="234" height="171" style="display: block; ">
                              </div>
                          </div>
                      </td>
                      <td style="padding: 0 3.5px;">

                          <div>
                              <div style="display: flex; margin-bottom: 8px;">
                                  <img src="../assets/img/annual-metting/005.jpg" alt=""
                                      width="234" height="171"
                                      style="display: block; margin-right: 7px">
                                  <img src="../assets/img/annual-metting/004.jpg" alt=""
                                      width="234" height="171">
                              </div>
                              <div style="display: flex; margin-bottom: 8px;">
                                  <img src="../assets/img/annual-metting/001.jpg" alt=""
                                      width="154" height="171"
                                      style="display: block; margin-right: 6px">
                                  <img src="../assets/img/annual-metting/005.jpg" alt=""
                                      width="154" height="171"
                                      style="display: block; margin-right: 6px">
                                  <img src="../assets/img/annual-metting/003.jpg" alt=""
                                      width="154" height="171">
                              </div>
                              <div style="display: flex;">
                                  <img src="../assets/img/annual-metting/005.jpg" alt=""
                                      width="234" height="171"
                                      style="display: block; margin-right: 7px">
                                  <img src="../assets/img/annual-metting/004.jpg" alt=""
                                      width="234" height="171" style="display: block; ">
                              </div>
                          </div>
                      </td>
                      <td style="padding: 0 3.5px;">

                          <div>
                              <div style="display: flex; margin-bottom: 8px;">
                                  <img src="../assets/img/annual-metting/005.jpg" alt=""
                                      width="234" height="171"
                                      style="display: block; margin-right: 7px">
                                  <img src="../assets/img/annual-metting/004.jpg" alt=""
                                      width="234" height="171">
                              </div>
                              <div style="display: flex; margin-bottom: 8px;">
                                  <img src="../assets/img/annual-metting/001.jpg" alt=""
                                      width="154" height="171"
                                      style="display: block; margin-right: 6px">
                                  <img src="../assets/img/annual-metting/005.jpg" alt=""
                                      width="154" height="171"
                                      style="display: block; margin-right: 6px">
                                  <img src="../assets/img/annual-metting/003.jpg" alt=""
                                      width="154" height="171">
                              </div>
                              <div style="display: flex;">
                                  <img src="../assets/img/annual-metting/005.jpg" alt=""
                                      width="234" height="171"
                                      style="display: block; margin-right: 7px">
                                  <img src="../assets/img/annual-metting/004.jpg" alt=""
                                      width="234" height="171" style="display: block; ">
                              </div>
                          </div>
                      </td>
                      <td style="padding: 0 2px 0 3.5px;">

                          <div>
                              <div style="display: flex; margin-bottom: 8px;">
                                  <img src="../assets/img/annual-metting/005.jpg" alt=""
                                      width="234" height="171"
                                      style="display: block; margin-right: 7px">
                                  <img src="../assets/img/annual-metting/004.jpg" alt=""
                                      width="234" height="171">
                              </div>
                              <div style="display: flex; margin-bottom: 8px;">
                                  <img src="../assets/img/annual-metting/001.jpg" alt=""
                                      width="154" height="171"
                                      style="display: block; margin-right: 6px">
                                  <img src="../assets/img/annual-metting/005.jpg" alt=""
                                      width="154" height="171"
                                      style="display: block; margin-right: 6px">
                                  <img src="../assets/img/annual-metting/003.jpg" alt=""
                                      width="154" height="171">
                              </div>
                              <div style="display: flex;">
                                  <img src="../assets/img/annual-metting/005.jpg" alt=""
                                      width="234" height="171"
                                      style="display: block; margin-right: 7px">
                                  <img src="../assets/img/annual-metting/004.jpg" alt=""
                                      width="234" height="171" style="display: block; ">
                              </div>
                          </div>
                      </td> -->
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
          </td>
          <td ref="demo2" id="demo2" valign="top"></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['expertData'],
  data() {
    return {
      expertList: []
    }
  },
  watch: {
    expertData(newVal, oldVal) {
      //console.log('newVal', newVal)
      // this.init()
      this.expertList = newVal
      setTimeout(this.init, 1500)
    }
  },
  methods: {
    openImg(e) {
      // console.log('e.target.innerHTML', e.target.currentSrc) , e.target.parentNode.children[1].innerHTML
      this.$emit('openExpert', e.target.currentSrc.split('?')[0], e.target.parentNode.children[1].innerHTML)
    },
    init() {
      var speed = 20

      //数值越大速度越慢

      demo2.innerHTML = demo1.innerHTML

      function Marquee() {
        if (demo2.offsetWidth - demo.scrollLeft <= 0) demo.scrollLeft -= demo1.offsetWidth
        else {
          demo.scrollLeft++
        }
      }


      let MyMar = setInterval(Marquee, speed)

      demo.onmouseover = function () {
        clearInterval(MyMar)
      }
      demo.onmouseout = function () {
        MyMar = setInterval(Marquee, speed)
      }
    }
    
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.annual-swiper-component {
  height: 538px;
  width: 100%;
  position: relative;

  #demo {
    overflow: hidden;
    height: 100%;
    width: 100%;
    color: #ffffff;
    /* margin: 0 auto; */
    position: absolute;
    top: 0;
    left: 0;
    // transform: translate(-50%, -50%);
  }

  table,
  tbody {
    margin: 0;
    padding: 0;
  }

  tr {
    margin: 0;
    padding: 0;
  }

  td {
    padding: 0;
  }
}
</style>