<template>
    <div class="annual-meeting-page">
        <HeaderAnnualMeeting :annualObj="annualObj" />
        <div class="annual-meeting">
            <div class="wordcloud-wrap">
                <!-- wid1200 -->
                <div class="com-wrap">
                    <img src="../assets/img/annual-metting/title1.png" alt="" />
                </div>
                <div class="wordcloud">
                    <!-- <WordCloudYear :cloudList="cloudList" /> -->
                    <img :src="annualObj.wordCloud + '?w=1200&h=200'" alt="" />
                </div>
            </div>

            <div class="review-wrap">
                <div class="com-wrap">
                    <img src="../assets/img/annual-metting/title4.png" alt="" />
                </div>
                <div class="review wid1200">
                    <ul>
                        <li v-for="item in reviewData" :key="item.id">
                            <!-- <img src="../assets/img/annual-metting/img-review.png" alt=""> -->
                            <img :src="item.image + '?w=585&h=307'" alt="" @click="goDetail(item.id)" style="cursor: pointer;"/>
                            <!-- <h6>{{item.title}}</h6>
                            <p>无锡</p>
                            <span>2019.10.25-10.27</span> -->
                        </li>
                        <!-- <li>
                            <img src="../assets/img/annual-metting/img-review2.png" alt="">
                            <h6>第八届人文社会科学集刊年会</h6>
                            <p>无锡</p>
                            <span>2019.10.25-10.27</span>
                        </li> -->
                    </ul>
                </div>

            </div>

            <div class="expert-wrap">
                <div class="com-wrap">
                    <img src="../assets/img/annual-metting/title3.png" alt="" />
                </div>
                <div class="expert">
                    <AnnualSwiper :expertData="expertData" @openExpert="openExpert" />
                </div>
            </div>

            <!-- 年会报道 -->
            <div class="news-wrap">
                <div class="com-wrap">
                    <img src="../assets/img/annual-metting/title2.png" alt="" />
                </div>
                <div class="news">
                    <img id="newsBg" src="../assets/img/annual-metting/bg-news2.png" alt="" />
                    <div class="news-content wid1200">
                        <ul>
                            <!-- 8 -->
                            <li v-for="item in reportData" :key="item.id">
                                <a :title="item.title" :href="item.url" target="_blank">{{item.title}}</a>
                                <span>{{item.releaseDate}}</span>
                                <!-- updateTime
                                createTime -->
                            </li>
                        </ul>
                        <div class="pagination-wrap" style="display: flex; justify-content: center; margin-top: 22px;">
                            <el-pagination  @current-change="handleNewsChange"
                                :current-page="newsNum" :page-size="8"
                                layout="prev, pager, next" :total="totalNews">
                                <!-- total, prev, pager, next, jumper -->
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
            

            

            <div class="chronicle-of-events-wrap">
                <div class="com-wrap">
                    <img src="../assets/img/annual-metting/title5.png" alt="" />
                </div>
                <div class="chronicle-of-events wid1200">
                    <div class="side">
                        <p>2012</p>
                        <span>|</span>
                        <p>2023</p>
                    </div>
                    <div class="chronicle">
                        <ul>
                            <li v-for="item in eventData" :key="item.id">
                                <div class="time">
                                    <h6></h6>
                                    <!-- <h6>{{item.releaseDate? item.releaseDate.slice(8,10): ''}}</h6> -->
                                    <p>{{item.releaseDate? item.releaseDate.slice(0,7) : ''}}</p>
                                </div>
                                <h5>
                                    <a :title="item.abstractCn" >{{item.abstractCn}}</a>
                                </h5>
                            </li>
                               <!-- </div>
                                <h5>
                                    <a title=" 我社联合广东省社会科学院召开“首届人文社会科学集刊年会”。"> 我社联合广东省社会科学院召开“首届人文社会科学集刊年会”。</a>
                                </h5>
                            </li> -->
                      
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <AnnualMeetingFooter />
        <el-dialog
          :visible.sync="isBigImg"
          >
           <div style="width: 100%; padding: 20px 0;"  @click.stop="isBigImg = false">
               <img style="display: block; margin: 0 auto;" :src="urlBigImg" @click.stop="isBigImg = true" alt="" />
               <h6 style="text-align: center; font-size: 14px; margin: 10px 0; color: #fff;" @click.stop="isBigImg = true" >{{nameBigImg}}</h6>
               <p style="padding: 0 20px; text-align: left; font-size: 14px;  color: #fff;" v-html="abstractBigImg" @click.stop="isBigImg = true" ></p>
           </div>
        </el-dialog>
    </div>
</template>

<script>
import HeaderAnnualMeeting from '../components/HeaderAnnualMeeting'
import AnnualMeetingFooter from '../components/AnnualMeetingFooter'
import WordCloudYear from '../components/WordCloudYear'
import AnnualSwiper from '../components/AnnualSwiper'

export default {
    components: { HeaderAnnualMeeting, AnnualMeetingFooter, WordCloudYear, AnnualSwiper },
    data() {
        return {
            annualObj: {},
            annualId: '',
            reportData: [], //年会报道
            expertData: [], // 年会人物
            reviewData: [], // 年会回顾
            eventData: [], // 大事纪
            cloudList: [],

            isBigImg: false,
            urlBigImg: '',
            urlBigVideo: '',
            isBigVideo: false,
            nameBigImg: '',
            abstractBigImg: '',
            totalNews: 0,
            newsNum: 1
        }
    },
    mounted () {
        // console.log('clientWidth', document.body.clientWidth-1200)
        var x = document.body.clientWidth - (document.body.clientWidth-1200)/2
        document.getElementById('newsBg').style.width = x + 'px';
    },
    methods: {
        handleNewsChange (val) {
            this.newsNum = val
            this.getList('MEETINGREPORT')
        },
        openExpert (url, id) {
            // console.log(url, id)
            if (url) {
                this.urlBigImg = url
                this.expertData.forEach(t => {
                    t.forEach(e=>{
                        if (e.id == id) {
                        this.nameBigImg = e.title
                        this.abstractBigImg = e.abstractCn
                        this.isBigImg = true
                    }
                    })
                    
                })
                // console.log(2)
                
            } else {
                return
            }
        },
        goDetail (i) {
            this.$router.push({path: '/amD/am', query: {id: i}})
        },
        getAnnualObj(num) {
            let _this = this
            return new Promise(function(resolve, reject) {
                _this
                    .$http({
                        method: 'get',
                        url: '/admin/api/annualMeeting/meetingList?newest=' + num
                    })
                    .then(res => {
                        if (num == 'Y') {
                            // _this.cloudList = res.data[0].cloudList
                            _this.annualObj = res.data[0]
                            _this.annualId = _this.annualObj.id
                        } else if (num == 'N') {
                            _this.reviewData = res.data
                        }
                        resolve(res.data)
                    })
            })
        },
        getList(type) {
            let _this = this
            var x = {
                meetingId: _this.annualId,
                resourceType: type
            }
            if (type == 'MEETINGREPORT') {
                x = {
                    meetingId: _this.annualId,
                    resourceType: type,
                    pageSize: 7,
                    pageNum: _this.newsNum
                }
            }
            return new Promise(function(resolve, reject) {
                _this
                    .$http({
                        method: 'get',
                        url: '/admin/api/annualMeeting/resourceList',
                        params: x
                    })
                    .then(res => {
                        if (type == 'MEETINGREPORT') {
                            // 年会报道
                            _this.reportData = res.data.data.rows
                            _this.totalNews = res.data.data.total
                        } else if (type == 'MEETINGPERSON') {
                            // 年会人物
                            var xx  = res.data.data.rows
                            var result = []
                            for(var i=0,len=xx.length;i<len;i+=7){
                                result.push(xx.slice(i,i+7));
                            }

                            _this.expertData = result




                        } else if (type == 'MEETINGEVENTS') {
                            // 大事纪
                            _this.eventData = res.data.data.rows
                            _this.eventData.forEach((e, ind) => {
                                e.abstractCn = e.abstractCn.replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n').replace(/<br\/?>/gi, '\n').replace(/<[^>/]+>/g, '').replace(/(\n)?<\/([^>]+)>/g, '').replace(/\u00a0/g, ' ').replace(/&nbsp;/g, ' ').replace(/<\/?(img)[^>]*>/gi, '').replace(/&amp;/g,"&").replace(/&lt;/g,"<").replace(/&gt;/g,">").replace(/&#39;/g,"\'").replace(/&quot;/g,"\"").replace(/<\/?.+?>/g,"")
                            })
                        } else {
                        }
                        resolve(res.data)
                    })
            })
        }
    },
    created() {
        
        let _this = this
        this.getAnnualObj('Y').then(res => {
            _this.getList('MEETINGREPORT').then(res => {
                // 年会报道
                _this.getList('MEETINGPERSON').then(res => {
                    // 年会人物
                    _this.getAnnualObj('N').then(res => {
                        // 年会回顾
                        _this.getList('MEETINGEVENTS').then(res => {
                            // 大事纪
                        })
                    })
                })
            })
        })
    }
}
</script>

<style lang="scss" scoped>
@mixin ellipse1 {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin ellipse3 {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}
.annual-meeting-page {
    .com-wrap {
        img {
            height: 93px;
            width: auto;
            margin: 45px auto;
            display: block;
        }
    }
    // .wordcloud {
    //     width: 100%;
    //     height: 375px;
    //     background: #f5f3f3;
    // }
    .wordcloud {
        width: 100%;
        padding: 60px 0;
        box-sizing: border-box;
        background: rgba(245, 243, 243, 0.5);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 1200px;
            display: block;
            height: 200px;
        }
    }
    .news {
        width: 100%;
        height: 448px;
        background: url(../assets/img/annual-metting/bg-news4.png);
        background-repeat: repeat-x;
        background-size: auto 100%;
        position: relative;
        z-index: 0;
        #newsBg {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            z-index: 0;
        }
        .news-content {
            
            z-index: 2;
            height: 100%;
            background: rgba(13, 16, 32, 0.5);
            // padding: 19px 30px 19px 20px;
            padding: 19px 25px;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            ul {
                height: 364px;
                li {
                    width: 100%;
                    height: 51px;
                    line-height: 51px;
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);

                    a {
                        width: 850px;
                        height: 51px;
                        line-height: 51px;
                        display: block;
                        position: relative;
                        padding-left: 17px;
                        color: #fff;
                        font-size: 16px;

                        cursor: pointer;
                        @include ellipse1();
                    }
                    a::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 6px;
                        height: 6px;
                        background: #ffffff;
                        border-radius: 50%;
                    }
                    span {
                        display: block;
                        font-size: 14px;
                        color: #fff;
                    }
                }
                li:nth-child(2n + 2) {
                    margin-right: 0;
                }
            }
        }
    }
    .expert {
        width: 100%;
        height: 530px;
        // background: red;
    }
    .review {
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                width: 585px;
                height: 307px;
                margin-right: 15px;
                margin-bottom: 15px;
                position: relative;
                z-index: 0;
                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                }
                h6 {
                    height: 35px;
                    text-align: center;
                    font-size: 33px;
                    font-weight: 800;
                    color: #1c323b;
                    line-height: 35px;
                    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.37);
                    background: linear-gradient(0deg, #b28355 0%, #8a5549 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-bottom: 50px;
                    padding-top: 70px;
                    // @include ellipse1();
                }
                p,
                span {
                    display: block;
                    text-align: center;
                    text-align: center;
                    height: 32px;
                    font-size: 30px;
                    font-weight: 800;
                    color: #1c323b;
                    line-height: 32px;
                    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.37);

                    background: linear-gradient(0deg, #b28355 0%, #8a5549 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin-bottom: 24px;
                }
            }
            li:nth-last-child(2), li:last-child {
                margin-bottom: 0;
            }
        }
    }
    .chronicle-of-events {
        display: flex;
        margin-bottom: 45px;
        .side {
            width: 118px;
            min-height: 450px;
            background: url(../assets/img/annual-metting/bg-time.png);
            background-repeat: no-repeat repeat;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
                font-size: 20px;
                font-family: Source Han Serif CN;
                font-weight: 800;
                color: #f3f1f1;
                text-align: center;
            }
            span {
                display: block;
                color: #fff;
                text-align: center;
                font-size: 20px;
            }
        }
        .chronicle {
            width: 1082px;
            min-height: 450px;
            background: #f5f5f5;
            ul {
                padding-bottom: 25px;
                li {
                    padding: 0 72px;
                    box-sizing: border-box;
                    margin-top: 25px;
                    display: flex;
                    align-items: center;
                    
                    .time {
                        width: 55px;
                        margin-right: 30px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: relative;
                        // margin-bottom: 18px;

                        h6 {
                            width: 22px;
                            height: 22px;
                            background: rgba(182, 173, 174, 0.5);
                            border-radius: 50%;
                            text-align: center;
                            line-height: 22px;
                            font-size: 14px;
                            font-family: FZKai-Z03S;
                            font-weight: bold;
                            color: #2b221b;
                            // margin-bottom: 4px;
                        }
                        p {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);

                            text-align: center;
                            font-size: 14px;
                            font-family: FZKai-Z03S;
                            font-weight: 400;
                            color: #2b221b;
                            line-height: 14px;
                        }
                    }
                    h5 {
                        // margin-bottom: 18px;
                        a {
                            width: 848px;
                            display: block;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #2b221b;
                            // @include ellipse1();
                        }
                    }
                }
            }
        }
    }
}
</style>